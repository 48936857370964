// src/generated/api/product-variant.ts
var VariantServerType = /* @__PURE__ */ ((VariantServerType2) => {
  VariantServerType2["Pc"] = "pc";
  VariantServerType2["Xbox"] = "xbox";
  VariantServerType2["Playstation"] = "playstation";
  return VariantServerType2;
})(VariantServerType || {});

// src/generated/api/product.ts
var MetaFlareStyle = /* @__PURE__ */ ((MetaFlareStyle2) => {
  MetaFlareStyle2["Ribbons"] = "ribbons";
  MetaFlareStyle2["Banners"] = "banners";
  return MetaFlareStyle2;
})(MetaFlareStyle || {});
var SelectVariantsStyle = /* @__PURE__ */ ((SelectVariantsStyle2) => {
  SelectVariantsStyle2["SelectMenu"] = "select-menu";
  SelectVariantsStyle2["VariantTiles"] = "variant-tiles";
  return SelectVariantsStyle2;
})(SelectVariantsStyle || {});
var ProductServerType = /* @__PURE__ */ ((ProductServerType2) => {
  ProductServerType2["Pc"] = "pc";
  ProductServerType2["Xbox"] = "xbox";
  ProductServerType2["Playstation"] = "playstation";
  return ProductServerType2;
})(ProductServerType || {});

// src/generated/api/server.ts
var ServerType = /* @__PURE__ */ ((ServerType2) => {
  ServerType2["Pc"] = "pc";
  ServerType2["Xbox"] = "xbox";
  ServerType2["Playstation"] = "playstation";
  return ServerType2;
})(ServerType || {});

// src/generated/components/cftools/Leaderboard.ts
var DefaultSortValue = /* @__PURE__ */ ((DefaultSortValue2) => {
  DefaultSortValue2["Kills"] = "kills";
  DefaultSortValue2["Deaths"] = "deaths";
  DefaultSortValue2["Suicides"] = "suicides";
  DefaultSortValue2["Playtime"] = "playtime";
  DefaultSortValue2["LongestKill"] = "longest_kill";
  DefaultSortValue2["LongestShot"] = "longest_shot";
  DefaultSortValue2["Kdratio"] = "kdratio";
  return DefaultSortValue2;
})(DefaultSortValue || {});

// src/generated/components/general/RequestConfiguration.ts
var Method = /* @__PURE__ */ ((Method2) => {
  Method2["Get"] = "GET";
  Method2["Post"] = "POST";
  return Method2;
})(Method || {});

// src/generated/components/navigation/NavItem.ts
var Target = /* @__PURE__ */ ((Target2) => {
  Target2["Self"] = "_self";
  Target2["Blank"] = "_blank";
  Target2["Parent"] = "_parent";
  Target2["Top"] = "_top";
  return Target2;
})(Target || {});

// src/generated/components/perks/SkinConfiguration.ts
var Type = /* @__PURE__ */ ((Type2) => {
  Type2["Player"] = "player";
  Type2["Group"] = "group";
  return Type2;
})(Type || {});

// src/generated/components/products/Addon.ts
var AddonType = /* @__PURE__ */ ((AddonType2) => {
  AddonType2["Checkbox"] = "checkbox";
  AddonType2["Quantity"] = "quantity";
  return AddonType2;
})(AddonType || {});

// src/generated/components/products/FormInput.ts
var FormInputType = /* @__PURE__ */ ((FormInputType2) => {
  FormInputType2["Text"] = "text";
  FormInputType2["Paragraph"] = "paragraph";
  FormInputType2["Number"] = "number";
  FormInputType2["Select"] = "select";
  FormInputType2["Radio"] = "radio";
  FormInputType2["Checkbox"] = "checkbox";
  FormInputType2["Color"] = "color";
  FormInputType2["Switch"] = "switch";
  FormInputType2["Date"] = "date";
  FormInputType2["Daterange"] = "daterange";
  return FormInputType2;
})(FormInputType || {});

// src/generated/components/site-configuration/Theme.ts
var ForceTheme = /* @__PURE__ */ ((ForceTheme2) => {
  ForceTheme2["Dark"] = "dark";
  ForceTheme2["Light"] = "light";
  return ForceTheme2;
})(ForceTheme || {});

// src/generated/components/site-configuration/Twitter.ts
var Card = /* @__PURE__ */ ((Card2) => {
  Card2["Summary"] = "summary";
  Card2["SummaryLargeImage"] = "summary_large_image";
  Card2["App"] = "app";
  Card2["Player"] = "player";
  return Card2;
})(Card || {});

// src/generated/extensions.ts
var allServerTypes = [
  "pc",
  "xbox",
  "playstation"
];
var castAnyServerType = (value) => value;
export {
  AddonType,
  Card,
  DefaultSortValue,
  ForceTheme,
  FormInputType,
  MetaFlareStyle,
  Method,
  ProductServerType,
  SelectVariantsStyle,
  ServerType,
  Target,
  Type,
  VariantServerType,
  allServerTypes,
  castAnyServerType
};
