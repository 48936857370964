// src/index.ts
var Constants = /* @__PURE__ */ ((Constants2) => {
  Constants2[Constants2["NS_IN_ONE_MS"] = 1e6] = "NS_IN_ONE_MS";
  Constants2[Constants2["MS_IN_ONE_SECOND"] = 1e3] = "MS_IN_ONE_SECOND";
  Constants2[Constants2["MS_IN_ONE_MINUTE"] = 6e4] = "MS_IN_ONE_MINUTE";
  Constants2[Constants2["MS_IN_ONE_HOUR"] = 36e5] = "MS_IN_ONE_HOUR";
  Constants2[Constants2["MS_IN_ONE_DAY"] = 864e5] = "MS_IN_ONE_DAY";
  Constants2[Constants2["MS_IN_ONE_WEEK"] = 6048e5] = "MS_IN_ONE_WEEK";
  Constants2[Constants2["MS_IN_ONE_MONTH"] = 2592e6] = "MS_IN_ONE_MONTH";
  Constants2[Constants2["MS_IN_ONE_YEAR"] = 31536e6] = "MS_IN_ONE_YEAR";
  return Constants2;
})(Constants || {});
var DISCORD_API_URL = "https://discord.com/api/v10";
var DISCORD_SNOWFLAKE_REGEX = /\d{18,22}/;
var DISCORD_GLOBAL_SNOWFLAKE_REGEX = /^\d{18,22}$/;
var DISCORD_ESCAPED_SNOWFLAKE_REGEX = DISCORD_SNOWFLAKE_REGEX.toString().slice(1, -1);
var STEAM_API_URL = "http://api.steampowered.com";
var CFTOOLS_API_URL = "https://data.cftools.cloud/v1";
var CFTOOLS_V2_API_URL = "https://data.cftools.cloud/v2";
var TICKETING_API_URL = "https://ticketing-api.mirasaki.dev";
export {
  CFTOOLS_API_URL,
  CFTOOLS_V2_API_URL,
  Constants,
  DISCORD_API_URL,
  DISCORD_ESCAPED_SNOWFLAKE_REGEX,
  DISCORD_GLOBAL_SNOWFLAKE_REGEX,
  DISCORD_SNOWFLAKE_REGEX,
  STEAM_API_URL,
  TICKETING_API_URL
};
