/* eslint-disable @typescript-eslint/no-explicit-any */

import { SnakeCase } from '@repo/api-types';
import { AnyServerType, castAnyServerType, Page, PopulatedProduct, PopulatedProductVariant, SiteConfiguration } from '@repo/api-types/generated';
import { Constants } from '@repo/constants';
import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { LucideIcon } from 'lucide-react';
import { Metadata } from 'next';

const NEXT_PUBLIC_CURRENCY_CODE = process.env.NEXT_PUBLIC_CURRENCY_CODE;
const NEXT_PUBLIC_CURRENCY_SYMBOL = process.env.NEXT_PUBLIC_CURRENCY_SYMBOL;
const NEXT_PUBLIC_BACKEND_URL = process.env.NEXT_PUBLIC_BACKEND_URL;

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const rgbToHex = (r: number, g: number, b: number): string => {
  return `#${((r << 16) + (g << 8) + b).toString(16).padStart(6, '0')}`;
};

export const hexToRgb = (hex: string): [number, number, number] => {
  const bigint = parseInt(hex.replace('#', ''), 16);
  return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
};

export const hexToHSL = (hex: string): [string, string, string] => {
  if (!/^#([A-Fa-f0-9]{6})$/.test(hex)) {
    throw new Error('Invalid hex color.');
  }

  const r: number = parseInt(hex.slice(1, 3), 16) / 255;
  const g: number = parseInt(hex.slice(3, 5), 16) / 255;
  const b: number = parseInt(hex.slice(5, 7), 16) / 255;

  const max: number = Math.max(r, g, b);
  const min: number = Math.min(r, g, b);

  // eslint-disable-next-line prefer-const
  let h: number, s: number, l: number = (max + min) / 2;

  if (max === min) {
    h = s = 0; // Achromatic (no color)
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    switch (max) {
    case r:
      h = (g - b) / d + (g < b ? 6 : 0);
      break;
    case g:
      h = (b - r) / d + 2;
      break;
    case b:
      h = (r - g) / d + 4;
      break;
    default:
      h = 0;
    }

    h /= 6;
  }

  return [
    (h * 360).toFixed(1),
    (s * 100).toFixed(1) + '%',
    (l * 100).toFixed(1) + '%',
  ];
};

export function colorFromString(string: string, saturation = 50, lightness = 75, defaultColor = '#000000') {
  // Check if the string is empty or null or has less then 3 characters
  if (!string) {
    return defaultColor;
  }
  if (string.trim().length < 3) {
    return defaultColor;
  }
  
  // Calculate the hue value based on the first three characters of the string
  const hue = string.charCodeAt(0) * string.charCodeAt(1) * string.charCodeAt(2);

  // Convert the saturation and lightness values from percentages to decimals between 0 and 1
  const s = saturation / 100;
  const l = lightness / 100;

  // Calculate the alpha value
  const a = s * Math.min(l, 1 - l);

  // Calculate the red, green, and blue values
  const f = (n: number) => {
    const k = (n + hue / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color).toString(16).padStart(2, '0');
  };

  // Return the hex color code
  const hexCode = `#${f(0)}${f(8)}${f(4)}`;
  return hexCode || defaultColor;
}

export function opacityFromString(string: string, defaultOpacity = 0.5) {
  if (!string) {
    return defaultOpacity;
  }
  if (string.trim().length < 3) {
    return defaultOpacity;
  }
  return (string.charCodeAt(0) * string.charCodeAt(1) * string.charCodeAt(2)) / Constants.MS_IN_ONE_SECOND;
}

export function hexColorWithOpacity(hex: string, opacity: number) {
  return hex + Math.round(opacity * 255).toString(16).padStart(2, '0');
}

export const floatingNavIconClassName =
  'h-4 w-4 text-neutral-500 dark:text-white'
  + ' group-hover:scale-125 group-hover:text-primary'
  + ' duration-300 ease-in-out transition-all';

export const displayCurrency = (amount: number): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: NEXT_PUBLIC_CURRENCY_CODE,
  });
  
  return formatter.format((amount / 100));
};
  
export const displayCurrencySymbol = (): string => NEXT_PUBLIC_CURRENCY_SYMBOL ?? '$';
  
export const displayCurrencyCode = (): string => NEXT_PUBLIC_CURRENCY_CODE ?? 'USD';

export const cmsURL = (): string => `${process.env.NEXT_PUBLIC_CMS_URL}`;

export const conditionalSlash = (path: string): string => path.startsWith('/') ? path : `/${path}`;

export const cmsImageUrl = (
  image: string
): string => `${cmsURL()}${
  conditionalSlash(image)
}`;

export const cmsLogo = (siteConfiguration: SiteConfiguration, preferredWidthHeight?: number): null | {
  url: string;
  width: number;
  height: number;
} => {
  if (!siteConfiguration.attributes.logo?.data?.attributes) {
    return null;
  }
  return {
    url: `${cmsImageUrl(
      siteConfiguration.attributes.logo.data.attributes.formats?.thumbnail?.url
      ?? siteConfiguration.attributes.logo.data.attributes.url
    )}?v=${
      siteConfiguration.attributes.logo.data.attributes.updatedAt.toString().replace(/[^0-9]/g, '')
    }`,
    width: siteConfiguration.attributes.logoWidthOverwrite ?? preferredWidthHeight ?? siteConfiguration.attributes.logo.data.attributes.width,
    height: siteConfiguration.attributes.logoHeightOverwrite ?? preferredWidthHeight ?? siteConfiguration.attributes.logo.data.attributes.height,
  };
};

export const pageMetadata = (
  siteConfiguration: SiteConfiguration,
  page: Page,
  applyNoRobotsForStoreOnlyMode = false,
): Metadata => {
  const { title, description, image } = page;
  const data: Metadata = {
    title,
    description,
  };

  if (image) {
    const imgData = {
      url: image.data.attributes.url,
      width: image.data.attributes.width,
      height: image.data.attributes.height,
      alt: image.data.attributes.alternativeText,
    };
    data.openGraph = {
      images: [ imgData ],
    };
    data.twitter = {
      images: [ imgData ],
    };
  }

  if (page.preventSearchEngineIndexing || (applyNoRobotsForStoreOnlyMode && siteConfiguration.attributes.storeOnlyMode)) {
    data.robots = {
      index: false,
      follow: false,
    };
  }

  return data;
};

export const isLucideIcon = (icon: unknown): icon is LucideIcon => {
  return typeof icon === 'object' && icon !== null && 'displayName' in icon && icon.displayName === 'LucideIcon';
};

export const displayRelativeTime = (date: Date): string => {
  const msDiff = date.getTime() - Date.now();
  const resolvedDiff = Math.abs(msDiff);

  if (resolvedDiff < Constants.MS_IN_ONE_MINUTE) {
    return 'just now';
  }

  const format = (value: number, unit: Intl.RelativeTimeFormatUnit) => {
    return new Intl.RelativeTimeFormat('en', { numeric: 'auto' }).format(value, unit);
  };

  if (resolvedDiff < Constants.MS_IN_ONE_HOUR) {
    return format(
      Math.round((date.getTime() - Date.now()) / Constants.MS_IN_ONE_MINUTE),
      'minute'
    );
  }

  if (resolvedDiff < Constants.MS_IN_ONE_DAY) {
    return format(
      Math.round((date.getTime() - Date.now()) / Constants.MS_IN_ONE_HOUR),
      'hours'
    );
  }

  if (resolvedDiff < Constants.MS_IN_ONE_WEEK) {
    return format(
      Math.round((date.getTime() - Date.now()) / Constants.MS_IN_ONE_DAY),
      'days'
    );
  }

  if (resolvedDiff < Constants.MS_IN_ONE_MONTH) {
    return format(
      Math.round((date.getTime() - Date.now()) / Constants.MS_IN_ONE_WEEK),
      'weeks'
    );
  }

  if (resolvedDiff < Constants.MS_IN_ONE_YEAR) {
    return format(
      Math.round((date.getTime() - Date.now()) / Constants.MS_IN_ONE_MONTH),
      'months'
    );
  }

  return format(
    Math.round((date.getTime() - Date.now()) / Constants.MS_IN_ONE_YEAR),
    'years'
  );
};

export const displayMs = (ms: number): string => {
  if (ms < Constants.MS_IN_ONE_HOUR) {
    return `${Math.round(ms / Constants.MS_IN_ONE_MINUTE)}m`;
  }
  if (ms < Constants.MS_IN_ONE_DAY) {
    return `${Math.round(ms / Constants.MS_IN_ONE_HOUR)}h`;
  }
  if (ms < Constants.MS_IN_ONE_WEEK) {
    return `${Math.round(ms / Constants.MS_IN_ONE_DAY)}d`;
  }
  if (ms < Constants.MS_IN_ONE_MONTH) {
    return `${Math.round(ms / Constants.MS_IN_ONE_WEEK)}w`;
  }
  if (ms < Constants.MS_IN_ONE_YEAR) {
    return `${Math.round(ms / Constants.MS_IN_ONE_MONTH)}mo`;
  }
  return `${Math.round(ms / Constants.MS_IN_ONE_YEAR)}y`;
};

export const isProductVariant = (product: PopulatedProduct | PopulatedProductVariant): product is PopulatedProductVariant => {
  return 'variantOf' in product && typeof product.variantOf === 'string' && product.variantOf.length > 0;
};

export const isAPIProductVariant = (product: SnakeCase<PopulatedProduct | PopulatedProductVariant>): product is SnakeCase<PopulatedProductVariant> => {
  return 'variant_of' in product && typeof product.variant_of === 'string' && product.variant_of.length > 0;
};

export const resolveProductServerType = (product: PopulatedProduct | PopulatedProductVariant): AnyServerType | null => {
  const serverType = (isProductVariant(product) ? product.variantServerType : product.productServerType) ?? null;
  return serverType ? castAnyServerType(serverType) : null;
};

/** 
 * Transforms the block content to replace placeholders with actual values.
 * Should be used sparingly as it can be quite performance intensive.
 * 1 Usage on a page is fine, but more than that can cause performance issues.
 */
export const transformBlockContent = (
  blocks: any,
  siteConfiguration: SiteConfiguration,
): any => {
  return blocks.map((e: { children: any[]; }) => ({
    ...e,
    children: e.children.map((f) => {
      if (f.type === 'link') {
        return {
          ...f,
          url: f.url === 'http://{{DISCORD_SERVER_INVITE_URL}}'
            ? siteConfiguration.attributes.discord.serverInviteURL
            : f.url,
        };
      }
      return f;
    }),
  }));
};

export const truncateText = (text: string, maxLength: number): string => {
  return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
};

export const getApplicationFeePercentage = (): number => {
  return parseFloat(process.env.NEXT_PUBLIC_APPLICATION_FEE_PERCENTAGE ?? '7.5');
};

export const getApplicationFee = (amount: number) => {
  return Math.round(amount * (getApplicationFeePercentage() / 100));
};

export const frontendURL = (): string => `${process.env.NEXT_PUBLIC_FRONTEND_URL}`;

export const titleCase = (str: string): string => {
  return str.toLowerCase().split(' ').map((word) => word.replace(word[0], word[0].toUpperCase())).join(' ');
};

export const reportFrontendError = (error: Error, jwt: string): void => {
  fetch(`${NEXT_PUBLIC_BACKEND_URL}/report-error`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
    body: JSON.stringify({
      name: error.name,
      message: error.message,
      stack: error.stack,
      cause: error.cause,
    }),
  });
};
